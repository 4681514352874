import React from 'react';
import style from './Works.module.scss';
import { RichText } from 'prismic-reactjs';
import { object, array } from 'prop-types';
import Image from '../../../../components/Image/Image';

const Works = ({ primary, items }) => {
  const { title, description } = primary;

  return (
    <div className={style.works}>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.title}>
            <RichText render={title.richText} />
          </div>
          {description && (
            <div className={style.description}>
              <RichText render={description.richText} />
            </div>
          )}
        </div>

        <div className={style.imageContainer}>
          <div className={style.ColumnOne}>
            {items.slice(0, 2)?.map((item, index) => {
              return (
                <a
                  href={item?.link?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  aria-label="link"
                >
                  <div className={style.SubContainer}>
                    <img
                      className={style.image}
                      src={item?.screenshot?.url}
                      alt={item?.screenshot?.url || 'image'}
                      loading="eager"
                    />
                  </div>
                </a>
              );
            })}
          </div>
          <div className={style.ColumnTwo}>
            {items.slice(2, 5)?.map((item, index) => {
              return (
                <a
                  href={item?.link?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  aria-label="link"
                >
                  <div className={style.SubContainer}>
                    <Image className={style.image} image={item?.screenshot} />
                    <div className={style.tag}>{item.tag.text}</div>
                  </div>
                </a>
              );
            })}
          </div>
          <div className={style.ColumnThree}>
            {items.slice(5, 7)?.map((item, index) => {
              return (
                <a
                  href={item?.link?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  aria-label="link"
                >
                  <div className={style.SubContainer}>
                    <Image className={style.image} image={item?.screenshot} />
                    <div className={style.tag}>{item.tag.text}</div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

Works.propTypes = {
  primary: object,
  items: array,
};

export default Works;
